import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import imageSource from 'compton/utils/image-source';
import { load } from 'ember-async-data';
import { all } from 'rsvp';

export default class AttachmentPreviewInternalComponent extends Component {
  /**
   * allowedBooks (array)
   * entity (object)
   */

  get imageUrl() {
    const entity = this.args.entity;

    if (entity) {
      if (entity.type === 'books' && entity.coverImage?.imageUrl) {
        return imageSource(entity.coverImage?.imageUrl, {
          preset: 'thumbnail',
        });
      } else if (entity.featured_image) {
        return imageSource(entity.featured_image, { preset: 'thumbnail' });
      } else if (entity.coverImage?.imageUrl) {
        return imageSource(entity.coverImage.imageUrl, { preset: 'thumbnail' });
      } else if (entity.defaultImage) {
        return entity.defaultImage;
      }
    }

    return null;
  }

  @cached
  get isValidProxy() {
    const promise = all([this.args.entity.book, this.args.allowedBooks]).then(
      ([book, allowedBooks]) =>
        allowedBooks?.map((obj) => obj.id)?.includes(book?.id)
    );

    return load(promise, this);
  }

  get isValid() {
    return this.isValidProxy.isResolved ? this.isValidProxy.value : false;
  }
}
