import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @collection.isDiagnosis}}\n  <Interactives::CollectionItem::Diagnosis\n    @collection={{@collection}}\n    @exercises={{this.exercises}}\n    @interactive={{this.interactive}}\n    @answers={{this.answers}}\n    @hasImageTemplate={{this.hasImageTemplate}}\n  />\n{{else if @collection.isCarousel}}\n  <Interactives::CollectionItem::Carousel\n    @collection={{@collection}}\n    @exercises={{this.exercises}}\n    @interactive={{this.interactive}}\n    @answers={{this.answers}}\n    @onOpenCollection={{this.handleOpenCollection}}\n    @hasImageTemplate={{this.hasImageTemplate}}\n  />\n{{else}}\n  <Interactives::CollectionItem::Standard\n    @collection={{@collection}}\n    @hasImageTemplate={{this.hasImageTemplate}}\n  />\n{{/if}}\n", {"contents":"{{#if @collection.isDiagnosis}}\n  <Interactives::CollectionItem::Diagnosis\n    @collection={{@collection}}\n    @exercises={{this.exercises}}\n    @interactive={{this.interactive}}\n    @answers={{this.answers}}\n    @hasImageTemplate={{this.hasImageTemplate}}\n  />\n{{else if @collection.isCarousel}}\n  <Interactives::CollectionItem::Carousel\n    @collection={{@collection}}\n    @exercises={{this.exercises}}\n    @interactive={{this.interactive}}\n    @answers={{this.answers}}\n    @onOpenCollection={{this.handleOpenCollection}}\n    @hasImageTemplate={{this.hasImageTemplate}}\n  />\n{{else}}\n  <Interactives::CollectionItem::Standard\n    @collection={{@collection}}\n    @hasImageTemplate={{this.hasImageTemplate}}\n  />\n{{/if}}\n","moduleName":"babel/components/interactives/collection-item/index.hbs","parseOptions":{"srcName":"babel/components/interactives/collection-item/index.hbs"}});
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import { resolve } from 'rsvp';

export default class InteractivesCollectionItem extends Component {
  @service router;

  @service assignmentEvents;

  @service missionMode;

  @tracked collection = this.args.collection;

  @cached
  get interactiveProxy() {
    const promise = this.assignmentEvents.getOrCreateInteractiveForCollection(
      this.collection
    );

    return load(promise, this);
  }

  get hasImageTemplate() {
    return this.args.interactive.template.includes('with_images');
  }

  get interactive() {
    return this.interactiveProxy.isResolved
      ? this.interactiveProxy.value.interactive
      : null;
  }

  get currentExercise() {
    return this.interactiveProxy.isResolved
      ? this.interactiveProxy.value.activeExercise
      : null;
  }

  get answers() {
    return this.interactive?.answers || [];
  }

  @cached
  get exercisesProxy() {
    const promise = resolve(this.collection?.children)
      .then((children) => this.missionMode.allowedEntities(children))
      .then((exercises) => {
        if (this.collection?.isTeacherCollection) {
          exercises = exercises.sortBy('title');
        }

        return exercises;
      });

    return load(promise, this);
  }

  get exercises() {
    return this.exercisesProxy.isResolved ? this.exercisesProxy.value : [];
  }

  @action
  handleOpenCollection(exercise) {
    return this.router.transitionTo(
      'master.contents.assignments.show',
      exercise.id
    );
  }

  @action
  resetCollection() {
    this.collection = this.args.collection;
  }
}
