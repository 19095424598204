import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"interactives-collection-item-thumbnail\">\n  <XImage class=\"interactives-collection-item-image\" @source={{this.imageURL}} />\n</div>", {"contents":"<div class=\"interactives-collection-item-thumbnail\">\n  <XImage class=\"interactives-collection-item-image\" @source={{this.imageURL}} />\n</div>","moduleName":"babel/components/interactives/collection-item/thumbnail/index.hbs","parseOptions":{"srcName":"babel/components/interactives/collection-item/thumbnail/index.hbs"}});
import Component from '@glimmer/component';

export default class InteractivesOneColumnWithImageThumbnail extends Component {
  get imageURL() {
    if (this.args.imageUrl) {
      return `${this.args.imageUrl}&w=240`;
    }

    return '/assets/images/defaults/interactives.svg';
  }
}
