import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import config from 'babel/config/environment';
import routeArgsForEntity from 'babel/utils/route-args-for-entity';
import { load } from 'ember-async-data';

function extractRouteArgs(args) {
  args = args.slice();
  const possibleQueryParams = args[args.length - 1];

  let queryParams;
  if (
    possibleQueryParams &&
    Object.prototype.hasOwnProperty.call(possibleQueryParams, 'queryParams')
  ) {
    queryParams = args.pop().queryParams;
  } else {
    queryParams = {};
  }

  const routeName = args.shift();
  const models = args;

  return { routeName, models, queryParams };
}

// NOTE When passing the params property directly to Ember's link-to helper,
// it expects the query params to be in this format.
function buildQueryParamsHash(queryParams) {
  return {
    isQueryParams: true,
    values: queryParams,
  };
}

export default class EntityLinkComponent extends Component {
  /**
   * Arguments:
   * closeMissionHeader (bool)
   * entity (object)
   * appearance (string)
   */

  @service contextHelper;

  @service missionMode;

  get isExternal() {
    return (
      this.args.entity?.type === 'books_external' ||
      this.args.entity?.type === 'books_junior'
    );
  }

  get externalUrl() {
    if (this.args.entity?.type === 'books_external') {
      return this.args.entity?.body?.external_url;
    } else {
      return `${config.juniorUrl}/books/${this.args.entity?.id}`;
    }
  }

  get initialQueryParams() {
    const initialQueryParams = {};
    const closeMissionHeader = this.args.closeMissionHeader;
    const section = this.args.section;

    if (section) {
      initialQueryParams.section = this.args.section.id;
    }

    if (closeMissionHeader) {
      initialQueryParams.customContentId = null;
      initialQueryParams.missionId = null;
    }

    return initialQueryParams;
  }

  @cached
  get routeArgsProxy() {
    return load(
      routeArgsForEntity(
        this.args.entity,
        this.initialQueryParams,
        this.missionMode
      ),
      this
    );
  }

  get routeArgs() {
    return this.routeArgsProxy.isResolved ? this.routeArgsProxy.value : null;
  }

  get routeParams() {
    if (this.routeArgs) {
      const { routeName, models, queryParams } = extractRouteArgs(
        this.routeArgs
      );

      return [routeName, ...models, buildQueryParamsHash(queryParams)];
    }

    return [
      'nodes',
      this.args.entity?.id,
      buildQueryParamsHash(this.initialQueryParams),
    ];
  }
}
