import { action } from '@ember/object';
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class EntityInspectorService extends Service {
  // PROPERTIES

  @tracked inspectedEntity = null;
  @tracked modalSection = null;
  @tracked modalIsOpen = false;

  // METHODS

  @action openModal(inspectedEntity, modalSection = 'about') {
    document.body.classList.add('entity-inspector--open');

    this.inspectedEntity = inspectedEntity;
    this.modalSection = modalSection;
    this.modalIsOpen = true;
  }

  @action closeModal() {
    document.body.classList.remove('entity-inspector--open');

    this.inspectedEntity = null;
    this.modalSection = null;
    this.modalIsOpen = false;
  }
}
