import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class MasterInteractivesIndexController extends Controller {
  @service contextHelper;

  @service missionMode;

  get interactive() {
    return this.model;
  }

  get showCoverImage() {
    return (
      this.interactive?.body?.show_cover_image && this.interactive.featuredImage
    );
  }

  get componentName() {
    switch (this.interactive.template) {
      case 'interactive':
        return 'interactives/standard';
      case 'interactive_one_col_with_images':
        return 'interactives/one-column';
      case 'interactive_two_col_with_images':
        return 'interactives/two-columns';
      case 'interactive_two_col_no_images':
        return 'interactives/two-columns';
      default:
        return 'interactives/standard';
    }
  }

  get restrictedCollections() {
    return this.interactive?.children
      ?.filter((child) => {
        if (this.contextHelper.inMission) {
          return this.missionMode.entityIsAllowed(child);
        }

        return true;
      })
      ?.sortBy('sort');
  }
}
