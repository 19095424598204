import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<XTab\n  @value={{this.value}}\n  @onChange={{fn (mut this.value)}}\n  {{did-update this.sectionChanged this.contextHelper.activeSection}}\n  ...attributes\n  as |tab|\n>\n\n  {{#each @versions as |version|}}\n\n    <EntityInspector::Detail::VersionTab::Pane\n      @tab={{tab}}\n      @version={{version}}\n    />\n\n  {{/each}}\n\n</XTab>", {"contents":"<XTab\n  @value={{this.value}}\n  @onChange={{fn (mut this.value)}}\n  {{did-update this.sectionChanged this.contextHelper.activeSection}}\n  ...attributes\n  as |tab|\n>\n\n  {{#each @versions as |version|}}\n\n    <EntityInspector::Detail::VersionTab::Pane\n      @tab={{tab}}\n      @version={{version}}\n    />\n\n  {{/each}}\n\n</XTab>","moduleName":"babel/components/entity-inspector/detail/version-tab/index.hbs","parseOptions":{"srcName":"babel/components/entity-inspector/detail/version-tab/index.hbs"}});
import { action } from '@ember/object';
import { once } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class EntityInspectorDetailVersionTabComponent extends Component {
  @service contextHelper;

  @tracked value = null;

  constructor() {
    super(...arguments);
    once(this, this.#setValueFromActiveSection);
  }

  @action
  sectionChanged() {
    once(this, this.#setValueFromActiveSection);
  }

  #setValueFromActiveSection() {
    const activeCategoryName = this.contextHelper.activeSection?.category?.name;
    const versions = this.args.versions || [];

    let version;

    if (activeCategoryName) {
      version = versions.find(
        (version) => version?.name === activeCategoryName
      );
    }

    if (!version) {
      version = versions[0];
    }

    if (version?.uuid && this.value !== version?.uuid) {
      this.value = version?.uuid;
    }
  }
}
