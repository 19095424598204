import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"interactives-cover-image-wrapper\">\n  <div class=\"interactives-cover-image\" style={{this.styleString}}></div>\n</div>", {"contents":"<div class=\"interactives-cover-image-wrapper\">\n  <div class=\"interactives-cover-image\" style={{this.styleString}}></div>\n</div>","moduleName":"babel/components/interactives/cover-image/index.hbs","parseOptions":{"srcName":"babel/components/interactives/cover-image/index.hbs"}});
import Component from '@glimmer/component';

export default class InteractivesCoverImage extends Component {
  get styleString() {
    const image = this.args.image;
    const focusPoint = image?.metadata?.focus_point;
    if (focusPoint) {
      const x = parseInt(focusPoint.x);
      const y = parseInt(focusPoint.y);
      return `--url: url(${image.imageUrl}); --focus-x: ${x}%; --focus-y: ${y}%;`;
    }

    const url = `url(${image.imageUrl});`;
    return `--url: ${url}`;
  }
}
