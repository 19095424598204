import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { scrollTo, waitForScrollEnd } from 'babel/utils/chapter-viewport';
import { load } from 'ember-async-data';
import { resolve } from 'rsvp';

export default class ContentExercises extends Component {
  @service assignmentEvents;

  @service contextHelper;

  @service router;

  @service missionMode;

  @tracked currentExerciseId;

  @cached
  get exercisesProxy() {
    const promise = resolve(this.args.section?.children).then((children) =>
      this.missionMode.allowedEntities(children)
    );

    return load(promise, this);
  }

  get exercises() {
    return this.exercisesProxy.isResolved ? this.exercisesProxy.value : null;
  }

  get allExerciseIds() {
    return this.exercises ? this.exercises.map((exercise) => exercise.id) : [];
  }

  @cached
  get interactiveProxy() {
    return load(this._interactivePromise(), this);
  }

  get answers() {
    return this.interactiveProxy.isResolved
      ? this.interactiveProxy.value.interactive?.answers
      : [];
  }

  get activeExercise() {
    return this.interactiveProxy.isResolved
      ? this.interactiveProxy.value.activeExercise
      : null;
  }

  get activeAnswer() {
    return this.interactiveProxy.isResolved
      ? this.interactiveProxy.value.activeAnswer
      : null;
  }

  @action
  async openExercise(exercise) {
    this.router.replaceWith({ queryParams: { exercise: null } });
    this.currentExerciseId = exercise;
    // awaiting the promise here reduces flashing when switching exercise,
    // as the data will already be loaded when the promise resolves
    await this._interactivePromise();
  }

  @action
  async handleUpdate(element, [exerciseId]) {
    if (element && this.allExerciseIds.includes(exerciseId)) {
      await waitForScrollEnd();
      await scrollTo(element);
    }
  }

  _interactivePromise() {
    let activeExercise;

    if (this.args.exerciseId) {
      activeExercise = this.exercises?.findBy('id', this.args.exerciseId);
    } else if (this.currentExerciseId) {
      activeExercise = this.exercises?.findBy('id', this.currentExerciseId);
    }

    return this.assignmentEvents
      .getOrCreateInteractiveForCollection(this.args.section, activeExercise)
      .then((data) => {
        if (
          data.activeExercise &&
          this.contextHelper.activeSection &&
          this.args.section &&
          this.contextHelper.activeSection.id === this.args.section.id
        ) {
          this.contextHelper.setActive('activeExercise', data.activeExercise);
        }

        return data;
      });
  }
}
